import React, { useEffect, useState } from 'react'
import { connect } from 'redux-zero/react'
import { useForm } from 'react-hook-form'
import { useController } from 'react-hook-form'
import { CreatePopWrapper } from './style'
import { Divider } from '@material-ui/core'
import { createPop } from '../../services/api'
import PopView from './PopView'
import Keys from '../../utils/Keys'
import actions from '../../store/actions'
import { CreatePop } from './CreatePop'
import { DEFAULT_FORM_VALUES, POP_TYPES } from './utils'

const PopCreation = ({
  users,
  selected_rid,
  logout,
  handleAlert,
  retailTags,
  groups,
}) => {
  const [isTimeLess, setIsTimeLess] = useState(false)
  const [checkedIds, setCheckedIds] = useState([])
  const [checkedRepopIds, setCheckedRepopIds] = useState([])
  const [checkedTags, setCheckedTags] = useState([])
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isPopBodyHasMaxHeight, setPopBodyHasMaxHeight] = useState(false)
  const [loading, setIsLoading] = useState(false)
  const [isPopImageError, setPopImageError] = useState(false)
  const [isProfileImageError, setProfileImageError] = useState(false)
  const [isVideoLinkError, setVideoLinkError] = useState(false)
  const [isAudioLinkError, setAudioLinkError] = useState(false)
  const [isRepopView, setIsRepopView] = useState(false)
  const [isPopPost, setIsPopPost] = useState(false)
  const [isCustomAvatar, setIsCustomAvatar] = useState(false)
  const [checkedDefaultPop, setCheckedDefaultPop] = useState(false)
  const [checkedRepop, setCheckedRepop] = useState(false)

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
    watch,
    resetField,
    setValue,
  } = useForm({
    defaultValues: DEFAULT_FORM_VALUES,
  })

  const popType = watch('pop_type')
  const { field } = useController({
    control,
    name: 'contexts_ids',
    rules: {
      required:
        popType === POP_TYPES.FAKE_REPOP || checkedTags.length > 0
          ? false
          : 'Please select users',
      minLenght: 1,
    },
  })
  const { field: tags_context } = useController({
    control,
    name: 'tags_context',
    rules: {
      required:
        popType === POP_TYPES.FAKE_REPOP || watch('contexts_ids')?.length > 0
          ? false
          : 'Please select tags',
      minLenght: 1,
    },
  })

  const { field: repop_contexts_ids } = useController({
    control,
    name: 'repop_contexts_ids',
    rules: {
      required:
        watch('pop_type') === POP_TYPES.DEFAULT_POP ||
        watch('pop_type') === POP_TYPES.CHAT_GPT
          ? false
          : 'Please select user',
      minLenght: 1,
    },
  })
  const handleResponseError = status => {
    if (status === '401') {
      logout()
    }
  }
  const handleSubmissionResponse = response => {
    let msg = 'ERROR: changes were not saved, something is wrong with the data'
    let alertType = Keys.ALERT_ERROR
    setIsLoading(false)

    if (response !== undefined && response.status === 200) {
      msg = 'Changes Were Saved :) !'
      alertType = Keys.ALERT_SUCCESS
      reset()
      setCheckedIds([])
      setCheckedRepopIds([])
      setCheckedTags([])
      setIsSubmitted(true)
      setIsLoading(false)
      setVideoLinkError(false)
      setAudioLinkError(false)
      setPopImageError(false)
    }

    handleAlert({
      text: msg,
      type: alertType,
    })
    handleResponseError(response.status.toString())
  }
  const getUsersIdsFromTags = () => {
    if (checkedTags.length > 0) {
      const tags = retailTags.filter(tag => checkedTags.includes(tag.sk))
      if (tags.length === 0) return []
      const tagsGroups = Array.from(
        new Set(tags.map(tag => tag.groups).flat(Infinity))
      )
      const uidsFromTagGroups = tagsGroups
        .map(group => groups[group].uids)
        .flat(Infinity)

      return uidsFromTagGroups
    }
    return []
  }
  const onSubmit = async (data, e) => {
    e.preventDefault()
    if (isPopBodyHasMaxHeight || isPopImageError || isProfileImageError) {
      return
    } else {
      let body =
        data.pop_type !== POP_TYPES.CHAT_GPT
          ? {
              rid: selected_rid,
              is_long_range_pop: isTimeLess,
              is_send_push_notification: checkedDefaultPop,
              data: {
                pop_title: data.pop_title,
                pop_body: data.pop_body,
                ack_btn_text: data.ack_btn_text,
                is_repop_send_push_notification: checkedRepop,
                push_title:
                  data.pop_type === POP_TYPES.DEFAULT_POP
                    ? data.push_title
                    : data.repop_push_title,
                push_body:
                  data.pop_type === POP_TYPES.DEFAULT_POP
                    ? data.push_body
                    : data.repop_push_body,
                repop_push_title:
                  data.pop_type === POP_TYPES.DEFAULT_POP
                    ? ''
                    : data.push_title,
                repop_push_body:
                  data.pop_type === POP_TYPES.DEFAULT_POP ? '' : data.push_body,
                profile_image_url:
                  data.pop_type === POP_TYPES.DEFAULT_POP || isCustomAvatar
                    ? data.profile_image_url || null
                    : users.find(
                        user => user.uid === data.repop_contexts_ids?.[0]
                      )?.avatar_profile_img_url
                    ? users.find(
                        user => user.uid === data.repop_contexts_ids?.[0]
                      )?.avatar_profile_img_url
                    : users.find(
                        user => user.uid === data.repop_contexts_ids?.[0]
                      )?.profile_img_url,
                pop_image_url:
                  data.pop_image_url !== '' ? data.pop_image_url : null,
                ...(data.repop_main_btn &&
                (data.pop_type === POP_TYPES.RE_POP ||
                  data.pop_type === POP_TYPES.FAKE_REPOP)
                  ? { repop_main_btn: data.repop_main_btn }
                  : {}),
                ...(data.video ? { video: data.video } : {}),
                ...(data.audio ? { audio: data.audio } : {}),
                ...(data.repop_sec_btn &&
                (data.pop_type === POP_TYPES.RE_POP ||
                  data.pop_type === POP_TYPES.FAKE_REPOP)
                  ? { repop_sec_btn: data.repop_sec_btn }
                  : {}),
                ...(data.pop_type === POP_TYPES.RE_POP && isPopPost
                  ? { is_pop_post: isPopPost }
                  : {}),
                ...(data.repop_contexts_ids &&
                data.pop_type === POP_TYPES.RE_POP
                  ? {
                      repop_contexts_ids: Array.from(
                        new Set([
                          ...data.contexts_ids,
                          ...getUsersIdsFromTags(),
                        ])
                      ),
                    }
                  : {}),
              },
              users:
                data.pop_type === POP_TYPES.DEFAULT_POP
                  ? Array.from(
                      new Set([...data.contexts_ids, ...getUsersIdsFromTags()])
                    )
                  : data.repop_contexts_ids,
              pop_type: data.pop_type,
            }
          : {
              data: {
                push_title: data.push_title,
                push_body: data.push_body,
                pop_body: data.pop_body,
                ack_btn_text: 'some text',
              },
              is_long_range_pop: isTimeLess,
              rid: selected_rid,
              pop_type: data.pop_type,
              users: Array.from(
                new Set([...data.contexts_ids, ...getUsersIdsFromTags()])
              ),
            }

      setIsLoading(true)
      createPop(handleSubmissionResponse, body)
    }
  }

  useEffect(() => {
    reset()
    setCheckedIds([])
    setVideoLinkError(false)
    setAudioLinkError(false)
    setPopImageError(false)
  }, [selected_rid])

  useEffect(() => {
    if (isSubmitted === false) {
      field.onChange(checkedIds)
      tags_context.onChange(checkedTags)
      if (popType === POP_TYPES.RE_POP || popType === POP_TYPES.FAKE_REPOP)
        repop_contexts_ids.onChange(checkedRepopIds)
    } else
      return () => {
        setIsSubmitted(false)
      }
  }, [checkedIds, checkedTags, popType, checkedRepopIds, isSubmitted])

  return (
    <CreatePopWrapper>
      <CreatePop
        loading={loading}
        watch={watch}
        register={register}
        reset={reset}
        errors={errors}
        users={users}
        checkedIds={checkedIds}
        setCheckedIds={setCheckedIds}
        checkedRepopIds={checkedRepopIds}
        setCheckedRepopIds={setCheckedRepopIds}
        handleSubmit={handleSubmit(onSubmit)}
        resetField={resetField}
        setPopBodyHasMaxHeight={setPopBodyHasMaxHeight}
        isPopBodyHasMaxHeight={isPopBodyHasMaxHeight}
        isPopImageError={isPopImageError}
        isProfileImageError={isProfileImageError}
        setValue={setValue}
        setIsRepopView={setIsRepopView}
        isPopPost={isPopPost}
        setIsPopPost={setIsPopPost}
        isCustomAvatar={isCustomAvatar}
        setIsCustomAvatar={setIsCustomAvatar}
        checkedTags={checkedTags}
        setCheckedTags={setCheckedTags}
        retailTags={retailTags}
        isTimeLess={isTimeLess}
        setIsTimeLess={setIsTimeLess}
        isSubmitted={isSubmitted}
        selected_rid={selected_rid}
        isVideoLinkError={isVideoLinkError}
        isAudioLinkError={isAudioLinkError}
        checkedDefaultPop={checkedDefaultPop}
        setCheckedDefaultPop={setCheckedDefaultPop}
        checkedRepop={checkedRepop}
        setCheckedRepop={setCheckedRepop}
      />
      <Divider orientation="vertical" flexItem style={{ height: 'inherit' }} />
      <PopView
        watch={watch}
        setPopBodyHasMaxHeight={setPopBodyHasMaxHeight}
        setPopImageError={setPopImageError}
        setProfileImageError={setProfileImageError}
        setIsRepopView={setIsRepopView}
        isRepopView={isRepopView}
        isCustomAvatar={isCustomAvatar}
        setVideoLinkError={setVideoLinkError}
        setAudioLinkError={setAudioLinkError}
      />
    </CreatePopWrapper>
  )
}

const mapStateToProps = ({
  users,
  selected_rid,
  loading,
  logout,
  handleAlert,
  groups,
  retailTags,
}) => ({
  users,
  groups,
  selected_rid,
  loading,
  logout,
  handleAlert,
  loading,
  retailTags,
})

export default connect(mapStateToProps, actions)(PopCreation)
